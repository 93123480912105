import React from 'react';
import algosdk from 'algosdk';
import {
  useWallet,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_PORT
} from '@txnlab/use-wallet'

const algodClient = new algosdk.Algodv2(DEFAULT_NODE_TOKEN, DEFAULT_NODE_BASEURL, DEFAULT_NODE_PORT)

export default function Transaction({ from, to, amount, gameLink }) {
  const { signTransactions, sendTransactions } = useWallet();

  const handleTransaction = async () => {
    try {
      const suggestedParams = await algodClient.getTransactionParams().do();

      const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from,
        to,
        amount,
        suggestedParams,
      });

      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);
      const signedTransactions = await signTransactions([encodedTransaction]);
      
      const waitRoundsToConfirm = 4;
      const { txId } = await sendTransactions(signedTransactions, waitRoundsToConfirm);

      console.log('Successfully sent transaction. Transaction ID: ', txId);

      // Then navigate to the game
      window.location.href = gameLink;
    } catch (error) {
      console.error('Transaction error:', error);
    }
  };

  return (
    <button onClick={handleTransaction}>
      Sign and send transaction
    </button>
  );
}
