import React from 'react';
import Modal from 'react-modal';
import { WalletProvider, useInitializeProviders, PROVIDER_ID } from '@txnlab/use-wallet';
import { DeflyWalletConnect } from '@blockshake/defly-connect';
import { PeraWalletConnect } from '@perawallet/connect';
import { DaffiWalletConnect } from '@daffiwallet/connect';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

Modal.setAppElement('#root'); // This line is added to specify the app element for react-modal

const Dapp = () => {
  const providers = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
      { id: PROVIDER_ID.EXODUS },
    ],
  });

  return (
    <WalletProvider value={providers}>
      <App /> {/* App is now child of Dapp */}
    </WalletProvider>
  );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Dapp /> {/* Render Dapp instead of App */}
  </StrictMode>,
);

